<template>
    <div>
        <v-app class="desktopLayoutClass">
            <div class="fullPageDiv">
                <div>
                    <div>
                        <div class="titleDiv">
                            <img style="width: 38px; height: 38px; margin-right: 15px;" src="https://d3vsnl8idgwrlw.cloudfront.net/redTick.svg"/>
                            <p class="ma-0 pa-0 light-red-color-text font-weight-semi-bold title-heading">Confirm Cancellation</p>
                        </div>
                        <p v-if="part1" style="font-size: 15px;" class="titleInfo">We’ll provide penalty charges for your cancellation based on your booking status</p>
                        <p v-if="part2" style="font-size: 15px;" class="titleInfo">This action is not reversible. Post cancellation refund will be intiated to your KBZPay Wallet.</p>
                    </div>

                    <v-divider></v-divider>

                    <div v-if="part1" class="part1">
                        <div class="segment">
                            <div class="segmentTitle font-weight-medium">
                                <p>Free Cancellation</p>
                            </div>
                            <div class="segmentInfo body-heading">
                                Customers can cancel their appointments 
                                <span class="light-red-color-text font-weight-semi-bold">free of charge up to 24 hours ahead</span>
                                of the scheduled booking time.
                            </div>
                        </div>
                        <div class="segment">
                            <div class="segmentTitle font-weight-medium">
                                <p>Cancellation With Penalty</p>
                            </div>
                            <div class="segmentInfo body-heading">
                                For cancellations made 
                                <span class="light-red-color-text font-weight-semi-bold">within 24 hours</span>
                                of the booking time, a 
                                <span class="light-red-color-text font-weight-semi-bold">cancellation fee of 30%</span>
                                of the booking cost will be applied.
                            </div>
                        </div>
                    </div>
                    <div v-if="part2" class="part2">
                        <div class="segment">
                            <div class="segmentTitle font-weight-medium">
                                <p>Reason For Cancellation</p>
                            </div>
                            <v-select
                                v-model="selectedCancellationReason"
                                placeholder="Choose From"
                                :items="reasonsForCancellation"
                                outlined
                            ></v-select>
                        </div>
                        <div class="segment">
                            <div class="segmentTitle font-weight-medium">
                                <p>Additional Comments</p>
                            </div>
                            <v-text-field
                                v-model="additionalComment"
                                outlined
                            ></v-text-field>
                        </div>
                    </div>
                </div>

                <div v-if="part1">
                    <v-divider style="margin-top: 0px;"></v-divider>

                    <div class="actionDiv">
                        <v-btn
                            style="background-color: #F2F2F2; color: #828282; border-radius: 4px;"
                            class="text-transform-none-class font-weight-semi-bold"
                            elevation="0"
                            @click="cancelLater()"
                            :disabled="actionDisabled"
                        >No, I’ll Cancel Later
                        </v-btn>
                        <v-btn
                            style="border-radius: 4px; background-color: white !important;"
                            class="text-transform-none-class font-weight-semi-bold light-red-color-text light-red-color-border"
                            elevation="0"
                            @click="cancelWithPenalty()"
                            :disabled="actionDisabled"
                        >Cancel Booking
                        </v-btn>
                    </div>
                </div>
                <div v-if="part2">
                    <v-divider style="margin-top: 0px;"></v-divider>

                    <div class="actionDiv">
                        <v-btn
                            style="background-color: #F2F2F2; color: #828282; border-radius: 4px;"
                            class="text-transform-none-class font-weight-semi-bold"
                            elevation="0"
                            @click="cancelLater()"
                            :disabled="actionDisabled"
                        >No, I’ll Cancel Later
                        </v-btn>
                        <v-btn
                            style="border-radius: 4px; background-color: white !important;"
                            :style="{ border: (selectedCancellationReason==='' || additionalComment==='') ? 'none' : '1px solid #EB5757' }"
                            class="text-transform-none-class font-weight-semi-bold light-red-color-text"
                            elevation="0"
                            @click="freeCancellation()"
                            :disabled="selectedCancellationReason==='' || additionalComment==='' || actionDisabled"
                        >Yes, I Confirm
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-app>
    </div>
</template>

<script>
import { axios_auth_instance_nurseBooking } from '../../utils/axios_utils';
import { mixinFunctions } from '../../mixins/index';
export default {
    name: "NBACustomerCancellation",
    data() {
        return {
            bookingID: '',
            part1: false,
            part2: false,
            reasonsForCancellation: [
                'Unexpected Health Issues', 'Unexpected Personal Emergency', 'Work or Personal Commitments', 'Family Emergency or Obligation',
            ],
            selectedCancellationReason: '',
            additionalComment: '',
            appointmentDetail: {},
            appointmentStartDate: '',
            dateMoreThan24Hours: true,
            actionDisabled: false
        }
    },
    mixins:[mixinFunctions],
    async mounted() {
        // getting BookingID from params
        var params = this.$route.params;
        this.bookingID = params["bookingID"];

        // Initially:
        // 10-24hrs: Penalty fees (Page1) -->
        // this.part1 = true;
        // more than 24hrs: reason page (Page2) -->
        // this.part2 = true;

        // New Update:
        // less than 24hrs, no button showed
        // more than 24hrs, free cancellation 
        // this.part2 = true;

        try {
            let fetchBookingDetailsResponse = await axios_auth_instance_nurseBooking.get(`/pagewise/bookingDetails?bookingID=${this.bookingID}`);
            this.appointmentDetail = fetchBookingDetailsResponse.data.data

            this.appointmentStartDate = this.appointmentDetail.date_shift.toString().slice(0, -1);

            this.checkIfDateMoreThan24Hours(this.appointmentStartDate);

        } catch (error) {
            console.log('[ERROR] CancelAppointmentPage: ', error);
            if (error.response.status === 401) {
                this.$cookies.remove('customerToken');
                this.$router.push({
                    name: "Login",
                });
            }
        }
        this.userPageViewTable('NBACancelAppointmentPage');
    },
    methods: {
        cancelLater() {
            this.part1 = false;
            this.part2 = false;

            this.$router.go(-1);
        },
        cancelWithPenalty() {
            
        },
        async freeCancellation() {
            this.actionDisabled = true;

            var cancelBody = {
                nurseID: this.appointmentDetail.nurse_ID,
                bookingID: this.appointmentDetail.pk,
                reason: this.selectedCancellationReason,
                comments: this.additionalComment
            };

            try {
                let cancellationResponse = await axios_auth_instance_nurseBooking.post(`/appointments/freeCancellation`, cancelBody);
                alert(this.appointmentDetail.pk + ' Cancellation Successful');
                this.$router.push({
                    name: "appointments",
                });
            } catch (error) {
                console.log('[ERROR][CancelNurseAppointment] Error in cancelling appointment: ', error);
                alert(this.appointmentDetail.pk + ' Error in cancelling appointment');
                this.$router.go(-1);
            }
        },
        checkIfDateMoreThan24Hours(targetDate) {
            let targetDateTime = new Date(
                parseInt(targetDate.substring(0, 4)),       // Year
                parseInt(targetDate.substring(4, 6)) - 1,   // Month (months are zero-based)
                parseInt(targetDate.substring(6, 8)),       // Day
            );

            let currentDate = new Date();
            
            // Calculate the time difference in milliseconds
            let timeDifference = targetDateTime - currentDate;

            // Check if the absolute difference is less than 24 hours (86400000 milliseconds)
            if (timeDifference < 86400000) {
                this.dateMoreThan24Hours = false;
                this.actionDisabled = true;
            } else {
                this.dateMoreThan24Hours = true;
                this.part2 = true;
            }
        }
    },
};
</script>

<style scoped lang="scss">
@import '../../../src/scss/classes.scss';
@import '../../../src/scss/global.scss';
.fullPageDiv {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
}
.titleDiv {
    display: flex;
    justify-content: flex-start;
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 15px;
    align-items: center;
}
.titleInfo {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    padding-right: 30px;
    padding-left: 30px;
    color: #A4A4A4;
}
.part1 {
    // padding: 30px;
    // border: 1px solid red;
}
.segment {
    margin: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.segmentTitle {
    color: #07090D;
    text-align: left;
}
.segmentInfo {
    background-color: #F4F6FA;
    padding: 15px;
    border-radius: 8px;
    color: #989595;
}
.actionDiv {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>